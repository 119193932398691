.pill {
    margin-right: 0.5 * $spacer;
    color: $white;
    font-weight: bold;
    vertical-align: top;

    &--category {
        background-color: $purple;
        color: $text-base;
    }

    &--label {
        background-color: $pink;
        color: $text-base;
    }

    &--topic {
        background-color: $primary-400;
        color: $text-base;
    }

    &--live {
        background-color: $feedback-color-rejected;
    }

    &--shortlist {
      background-color: $white;
      color: $text-base;
    }

    &--ghost {
        background-color: transparent;
        color: $text-base;
        border: 1px solid currentcolor;
    }
}

.pill__list {
    list-style: none;
    margin: 0;
    padding: 0;

    &--inline {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5 * $spacer;

        .pill {
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}
