/* ModeratorStatus.jsx - Berlin Online Redesign */
.moderator-status {
    display: inline-flex;
    align-items: center;
    padding: 0.5em 0;
    gap: 0.5em;
}

.moderator-status__color-indicator {
    display: inline-block;
    position: relative;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
}

.moderator-status__label {
        &--consideration,
        &--qualified {
            background-color: $feedback-color-consideration;
            
        }
    
        &--rejected {
            background-color: $feedback-color-rejected;
        }
    
        &--accepted {
            background-color: $feedback-color-accepted;
        }
}
