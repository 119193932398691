.actionable-list {
    list-style: none;
    padding: 0;
    margin: 0 0 2.5em;
}

.actionable-list__header {
    margin-top: .25rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        margin-top: 0;
        margin-bottom: 0;
        flex-shrink: 1;
    }
}

.actionable-list__header__action {
    flex: 0 0 auto;
    margin-left: .25rem;
}

.actionable-list__item {
    padding: 1.5em 0;

    &:not(.actionable-list__item--hide-last-line):last-child {
        border-bottom: 1px solid $gray-lighter;
    }

    &:not(.actionable-list__item--hide-first-line:first-child) {
        border-top: 1px solid $gray-lighter;
    }
}

.actionable-list__item__wrapper {
    margin-bottom: 0;

    @media screen and (min-width: $breakpoint-tablet) {
        display: flex;
    }
}

.actionable-list__item__body {
    display: flex;
}

.actionable-list__item--faded {
    color: $gray-light;

    h4,
    .bicon {
        color: $gray-light;
    }

    a {
        text-decoration-color: $gray-light;
    }
}

.actionable-list__item__actions {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    text-align: right;
    margin-top: 1.5em;

    @media screen and (min-width: $breakpoint-tablet) {
        margin-top: 0;
    }
}

.actionable-list__item__actions--inline {
    margin-top: 0;
}

.actionable-list__item__actions__bottom {
    margin-top: 1em;
}

.actionable-list__item__details {
    margin-left: .75em;
}

.actionable-list__item__buttons {
    margin-top: 1.19rem;
    order: 2;

    @media screen and (min-width: $breakpoint-tablet) {
        margin-top: 0;
        order: 0;
    }
}