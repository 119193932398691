// narrow wrapper appears on login, signup, forgot password pages
.narrow-wrapper {
    max-width: 30rem;
    margin: 0 auto;
}

.narrow-wrapper__secondary-cta {
    padding: 0.8em 0;
    text-align: right;

    @media (min-width: $breakpoint-tablet) {
        margin-left: 1em;
    }
}

.narrow-wrapper__secondary-cta--left {
    padding-left: 0;
    margin-left: 0;
    text-align: left;
}

.narrow-wrapper__footer {
    padding: 1em 0 3em;

    &--decoration {
        display: block;
        position: absolute;
        height: 8px;
        box-shadow: 0 0 0 100vmax $primary;
        clip-path: inset(0 -100vmax);
    }

    @media (min-width: $breakpoint-tablet) {
        padding: 1.2em 0 4.6em;
    }

}
