.project-actions {
    display: flex;
    align-items: center;

    &:first-child {
        margin-top: 3rem;
    }
}

.project-actions__right {
    color: $link-color;
    margin-left: auto;

    .fa {
        color: $link-color;
    }
}
