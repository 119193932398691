.user-indicator__container {
    grid-area: contentheader;
    grid-column: mainbar;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        background-color: $secondary;
        inset: 0 0 0 -9999px;
        display: block;
        content: "";
        z-index: -1;
        box-shadow: 9999px 0 0 $secondary;
        border-left: 9999px solid $secondary;
    }

    button {
        padding: .5em 0;
    }

    [role="menu"] {
      display: none;
    }

    [aria-expanded="true"] + [role="menu"] {
      display: block;
    }

    > .dropdown {
        @extend .content-language-select;

        align-self: flex-end;
        color: var(--color-white);

        & h2 {
            margin: 0;
        }
    }
}
