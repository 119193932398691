.kiezradar__loading {
  color: $gray;
  display: flex;
  margin-top: 3rem;
  text-transform: capitalize;
}

.kiezradar {
  margin-top: 2.5rem;
}

.kiezradar__nav {
  border-bottom: 1px solid $gray-lighter;
  margin-bottom: -10px;
  padding: 0;
  display: flex;
  gap: 1.3rem;
}

.kiezradar__nav-item {
  list-style: none;
  padding: 0;
}

.kiezradar__link {
  color: $black;
  padding: 0.5rem 0.25rem;
  border-bottom: 4px solid transparent;
}

.kiezradar__link,
.kiezradar__link:hover,
.kiezradar__link:focus {
  text-decoration: none;
}

.kiezradar__link--active {
  border-bottom-color: $primary;
  font-weight: bold;
}

.kiezradar-list__button:hover {
  text-decoration: underline;
}

.kiezradar-list {
  margin-top: 2.5rem;
}

.kiezradar-list__list {
  padding: 0;
}

.kiezradar-list__button-container {
  text-align: right;
}

.kiezradar-list__button:after {
  transform: scale(1.3);
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNy4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjUgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTI1NiA4MGMwLTE3LjctMTQuMy0zMi0zMi0zMnMtMzIgMTQuMy0zMiAzMmwwIDE0NEw0OCAyMjRjLTE3LjcgMC0zMiAxNC4zLTMyIDMyczE0LjMgMzIgMzIgMzJsMTQ0IDAgMCAxNDRjMCAxNy43IDE0LjMgMzIgMzIgMzJzMzItMTQuMyAzMi0zMmwwLTE0NCAxNDQgMGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMmwtMTQ0IDAgMC0xNDR6Ii8+PC9zdmc+") !important;
}

.kiezradar-list__kiezradar {
  border-bottom: 1px solid $gray-lighter;
  list-style: none;
  padding-bottom: 1rem;
  padding-top: 1rem;

  @media screen and (min-width: $breakpoint-palm) {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
}

.kiezradar-list__header {
  display: flex;
  gap: 3rem;
  margin-bottom: 0.5rem;

  @media screen and (min-width: $breakpoint-palm) {
    margin-bottom: 1.2rem;
  }
}

.kiezradar-list__kiezradar-title {
  margin-bottom: 1rem;
  margin-top: 0;

  @media screen and (min-width: $breakpoint-palm) {
    margin-bottom: 0.25rem;
  }
}

.kiezradar-list__kiezradar-title:last-child {
  margin-bottom: 0;
}

.kiezradar-list__header-buttons {
  display: none;

  @media screen and (min-width: $breakpoint-palm) {
    display: block;
    margin-left: auto;
  }
}

.kiezradar-list__buttons {
  display: flex;
  gap: 1.5rem;
}

.kiezradar-list__button:link,
.kiezradar-list__button:visited {
  color: $text-base;
}

.kiezradar-list__footer {
  @media screen and (min-width: $breakpoint-palm) {
    display: flex;
    align-items: flex-start;
  }
}

.kiezradar-list__view-projects {
  display: block;

  @media screen and (min-width: $breakpoint-palm) {
    display: inline;
    margin-left: auto;
  }
}

.kiezradar-list__footer-buttons {
  display: flex;
  margin-top: 1rem;

  @media screen and (min-width: $breakpoint-palm) {
    display: none;
  }
}

.kiezradar-list__footer-buttons div:only-of-type {
  margin-left: auto;
}

.kiezradar__error {
  margin-top: 3rem;
}

.kiezradar__form-button {
  text-align: right;

  @media screen and (min-width: $breakpoint-palm) {
    text-align: left;
  }
}

.kiezradar__map {
  margin-bottom: 2.5rem;
  position: relative;
  z-index: 0;

  @media screen and (min-width: $breakpoint-tablet) {
    margin-bottom: 2rem;
    position: relative;
    align-items: start;
  }

  .leaflet-top.leaflet-right {
    display: none;
    width: 100%;

    @media screen and (min-width: $breakpoint-tablet) {
      display: block;
      width: block;
    }
  }
}

.leaflet-top.leaflet-right .leaflet-control {
  height: 77px;
  margin: 0;
  width: 100%;

  @media screen and (min-width: $breakpoint-tablet) {
    margin: 10px 10px 0 0;
    width: auto;
  }
}

.kiezradar__map-search.leaflet-control {
  z-index: 1001;
}

.kiezradar__controls--tablet {
  display: none;

  @media screen and (min-width: $breakpoint-tablet) {
    display: block;
  }
}

.kiezradar__controls--mobile {
  display: block;
  margin-bottom: 1rem;

  @media screen and (min-width: $breakpoint-tablet) {
    display: none;
  }
}

.kiezradar__radius-controls {
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  padding: 1rem;

  @media screen and (min-width: $breakpoint-tablet) {
    width: 18.75rem;
  }
}

.kiezradar__radius-info {
  display: flex;
  font-size: 1rem;
  margin-bottom: 8px;
  line-height: 1.2;
}

.kiezradar__radius-label {
  margin-bottom: 0;
}

.kiezradar__radius-measurement {
  margin-left: auto;
}

.kiezradar__range-slider {
  appearance: none;
  width: 100%;
  height: 0.5rem;
  border-radius: 2px;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: 3px auto Highlight;
    outline: 3px auto -webkit-focus-ring-color;
    outline-offset: 3px;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: $primary;
    cursor: pointer;
    transition: background 0.15s ease-in-out;

    &:hover {
      transform: scale(0.97);
    }

    &:active {
      transform: scale(0.92);
    }
  }
}
