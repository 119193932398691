.button {
    color: $text-base!important;
    font-weight: 400;

    &:before {
        background-color: $primary;
        color: $text-base;
    }

    &:after {
        color: $text-base;
    }
}

.button--full-width {
    width: 100%; // enforce full-width on all viewports (used on narrow containers on desktop e.g. login page)
}

.button.button--proposal {
    background-color: $black;
    color: $text-inverted;
    transition: background-color 0.2s ease-in-out;

    &:not(:disabled):hover {
        background-color: $primary-600;
    }

    .fas {
        color: inherit;
        margin-left: .5em;
    }

    &--selected {
        background-color: $primary;

        // for some reason the :focus state gets a default color equal to primary-600
        // if nothing is set, so we explicitly set the color
        &:not(:disabled):focus {
            background-color: $primary;
        }

        &:not(:disabled):hover {
            background-color: $primary-600;
        }
    }

    &--read-only {
        background-color: $gray-lighter;
    }
}

.button.button--ghost {
    background-color: transparent;
    color: $text-base;
    border: 2px solid $text-base;
}
