.control-bar {
    .facet-title {
        font-size: 1.0625rem;
    }
}

.control-bar--no-spacing {
    margin-bottom: 0;
}

.control-bar--spacing {
    margin-bottom: 1.5em;
}

.control-bar__bottom-overlap {
    position: relative;
    z-index: 1;
    top: -1em;
}

.control-bar__filter-btn:focus,
.control-bar__action-btn:focus {
    outline-offset: -3px;
}

.control-bar__bottom {
    align-items: center;
}

.control-bar__bottom--projects {
    display: flex;
    flex-direction: column-reverse;
    padding: 1em 0;

    @media screen and (min-width: $breakpoint-tablet-landscape) {
        padding: 1em 0.875em;
    }

    @media screen and (min-width: $breakpoint-palm) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .pill__list {
            margin-right: auto;
            padding-right: 1em;
        }
    }
}

.modul-facetingform .facetingform__container .control-bar__checkboxes {
    margin-bottom: 1.5em;
}

.modul-facetingform .facetingform__container .control-bar__checkboxes-legend {
    float: left;
    line-height: 1.2;
    margin-right: 1rem;
    font-weight: normal;
}

.control-bar__form-check-inline {
    align-items: center;
    display: flex;
    margin-bottom: 0;
}

.control-bar__check-input[type="checkbox"] {
    margin-top: 0;
}
