.leaflet-container {
    .leaflet-popup-content {
        display: flex;
        flex-direction: row-reverse;

        min-height: 8em;
        margin: 0;
    }

    .leaflet-popup-content a {
        color: $black;
        font-weight: bold;
    }

    .leaflet-popup-content-wrapper {
        padding: 0;
        border-radius: 0;
    }
}

.maps-popups-popup-text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: $font-size-xs;

    padding: $padding;
    width: 14.5rem;
}

.maps-popups-popup-name {
    line-height: normal;
    page-break-inside: avoid;
    overflow-wrap: break-word;
    margin-bottom: .5em;
}

.maps-popups-popup-meta {
    padding-bottom: 0.5 * $spacer;
    display: flex;
    flex-wrap: wrap;
}

.map-popup-upvotes,
.map-popup-downvotes,
.map-popup-vote-count,
.map-popup-comments-count {
    margin-right: $spacer;
    white-space: nowrap;

    &:last-child {
        margin-right: 0;
    }
}

.maps-popups-popup-image {
    width: 8em;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.maps-popups-popup-no-image {
    display: none;
}
