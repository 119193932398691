.pollquestionlist-container,
.poll__preliminary-results {
    border: none;
    background-color: $gray-lightest;
    padding: 18px;
    padding-bottom: 0;

    form {
        margin: 0;
        padding: 0;
    }
}

.poll {
    h2 {
        font-size: 1.0625em;
        margin-top: 0;
    }
}

.poll:last-of-type {
    padding: 1rem 0;
}

.poll--question {
    padding: 1em 0;
    border-bottom: 1px solid $border-color;
}

.poll--result {
    border-bottom: 1px solid $border-color;
    padding: 1em 0;

    &:first-child {
        padding-top: 0;
    }
}

.poll__rows {
    .a4-muted {
        font-size: $font-size-sm;
        color: $gray;
    }
}

.poll-row {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-bottom: 0.5 * $spacer;
    font-weight: normal;

    &--answered {
        left: 2 * $spacer;
    }

    .form-control {
        margin-left: $spacer;
    }
}

.poll__choice--other {
    font-weight: 400;
    width: 100%;
    
    .a4-textarea-with-counter {
      padding-top: $spacer;
    }
}

.poll__char-counter {
    align-self: flex-end;
    font-size: $font-size-xs;
    color: $gray;

    span {
        visibility: hidden;
    }
}

.poll__actions > :last-child {
    margin-top: 0.5 * $spacer;
    display: block;

    @media (min-width: $breakpoint-palm) {
        display: inline-block;
    }
}

.poll-row .radio {
    @extend .form-check;
    @extend .form-check-inline;
}

.poll-row__radio {
    @extend .form-check-input;
}

.poll-row__number,
.poll-row__label,
.poll-row__radio {
    @extend .form-check-label;
}

.poll-row__container {
    position: relative;
}

.poll__btn--wrapper {
    display: flex;
    flex-direction: row-reverse;
}

.poll__btn--link {
    margin-right: $spacer;

    color: $link-color;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.poll__btn--dark {
    @extend .button;
    @extend .button--fullwidth-palm;
}

.poll-row__bar {
    background-color: $gray-lighter;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.3em;
    z-index: -1;
    width: 0;
    min-width: 0.2em;

    transition: width 0.3s;

    &.poll__highlight {
        max-width: 90%;

        @media (min-width: $breakpoint-palm) {
            max-width: 96%;
        }
    }
}

.poll__highlight {
    background-color: $message-light-green;
}

.poll-row__chosen {
    color: $primary;
    position: absolute;
    top: 0.25 * $spacer;
}

.poll-row__number {
    padding-left: 0.5 * $spacer;
    min-width: 5em;
    font-weight: bold;
}

.editpoll__btn--question {
    margin: 0.5 * $spacer 0;
}

.editpoll__help-text,
.poll__help-text {
    color: $gray;
    margin-top: -0.7 * $spacer;
    margin-bottom: 0.8 * $spacer;
    font-size: 14px;
}

.editpoll__help-text {
    color: $gray-lighter;
    margin-top: -0.7em;
    margin-bottom: 0.5em;
}

.editpoll__dropdown .dropdown-menu {
    border-radius: 0.3em;
    box-shadow: none;
}

.editpoll__btns--question {
    display: flex;
    flex-flow: column wrap;
}

@media (min-width: $breakpoint-palm) {
    .editpoll__btns--question {
        flex-direction: row;
    }

    .editpoll__btn--question {
        margin: 0;
        margin-right: 0.5 * $spacer;
    }
}

// Open/Other answers
.poll-slider__container {
    overflow: hidden;
}

.poll-slider__answer {
    margin-bottom: 1.5 * $spacer;
    padding-bottom: 2 * $spacer;

    i {
        color: $primary;

    }
}

.poll-slider__count,
.poll-slider__count--spaced {
    font-size: $font-size-xs;
    color: $gray-lighter;
    position: absolute;
    bottom: 1 * $spacer; // to align with arrows
}

.poll-slider {
    position: relative;

    // slick overwrites - nested for specificity
    .slick-prev {
        left: revert !important;
        right: 2 * $spacer !important;
    }

    .slick-next {
        right: 0;
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        z-index: 4; // for when tile links overlap
        color: transparent; // this instead of 'visibility: hidden' to keep keyboard navigation

        &.slick-disabled {
            opacity: 0.25;
            cursor: not-allowed;
            pointer-events: none;
            box-shadow: none;
        }

        &:before {
            color: $text-light;
            opacity: 1;
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            font-size: 1.2em;
        }
    }

    .slick-prev:before {
        content: "\f053";
    }

    .slick-next:before {
        content: "\f054";
    }

    // Ensure centermode works
    .slick-slide {
        height: auto;
        position: relative;
        border-radius: 0.3 * $spacer;
        background-color: $gray-lightest;
    }

    .slick-track {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
    }
}

@media (min-width: $breakpoint-palm) {
    .poll-slider__answer {
        margin-bottom: 0;
    }

    .poll-slider__count--spaced,
    .poll-slider__count {
        left: revert;
        right: 5 * $spacer;
        bottom: 1.5em;
    }
}
