// FIXME: remove if not needed anymore
.input {
    position: relative;

    .fa {
        font-size: 1.25em;
    }

    .input__element {
        line-height: normal;
        padding-top: 0.3125 * $em-spacer;
        padding-bottom: 0.3125 * $em-spacer;
        min-height: 2.5 * $em-spacer;
    }

    .input__element--before {
        padding-left: 2 * $em-spacer;
    }

    .input__element--after {
        padding-right: 2 * $em-spacer;
    }
}

.input__before,
.input__after {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 1.25 * $em-spacer;
    margin: auto;
    pointer-events: none;

    button {
        padding: 0;
        pointer-events: all;
    }
}

.input__before {
    left: 0.625 * $em-spacer;
}

.input__after {
    right: 0.625 * $em-spacer;
}
