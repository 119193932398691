.icon-switch {
    display: inline-block;
    text-align: left;
    width: 100%;

    .button {
        flex: 1;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        width: auto;

        .button {
            flex: none;
        }
    }
}

.icon-switch--full-width {
    width: 100%;

    .button {
        flex: 1;
    }
}

.icon-switch__group {
    display: flex;
    justify-content: end;
    align-items: center;

    .button {
        margin-left: 0;
    }

    .button.button--fulltone {
        border-width: 2px;
    }
}
