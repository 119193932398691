.rating {
    display: flex;

    @media screen and (min-width: $breakpoint-palm) {
        display: inline-block;
        white-space: nowrap;
    }
}

.rating__label {
    word-break: normal;
}

.rating-button {
    color: $link-color;
    margin-right: 1em;
    padding: 0;

    i {
        display: inline;
        font-weight: normal;
    }

    &--active,
    &.is-selected {
        font-weight: bold;
    }

    &:hover {
        text-decoration: underline;
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: $gray-light;
    }
}
