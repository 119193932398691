/* stylelint-disable no-descending-specificity */
.a4-btn--follow,
.a4-btn--following {
    @extend .button;
    @extend .button--fullwidth-palm;
    @extend .button--light;
    @extend .button--fulltone;
    color: $text-inverted !important;

    &:not(:disabled):hover {
        background-color: $primary-600;
    }
}

.a4-btn--following {
    background-color: $primary;

    &:not(:disabled):focus {
        background-color: $primary;
    }

    &:not(:disabled):hover {
        background-color: $primary-600;
    }
}

.a4-follow__btn--content:after {
    font-family: "Font Awesome 6 Free", sans-serif;
    font-weight: 900;
    margin-left: 8px;
}

.a4-btn--follow .a4-follow__btn--content:after {
    content: "\f067"; // fa-plus icon
}

.a4-btn--following .a4-follow__btn--content:after {
    content: "\f00c"; // fa-check icon
}