.nextprev {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;

    &__prev {
        grid-column: 1;
        justify-self: start;
        width: 100%;
        display: inline-block;
    }

    &__next {
        grid-column: 2;
        justify-self: end;
        width: 100%;
        display: inline-block;
        text-align: end;

        .fa {
            margin-left: .4em;
            margin-right: 0;
        }
    }
}
