@import "~bootstrap/scss/dropdown"; // import needed for a4-comments bootstrap dropdown, overwrites below

.dropdown {
    @extend .content-language-select;
    position: relative;

    i {
        font-size: 1.25em;
    }
}

.dropdown__item {
    margin-bottom: 0;

    a {
        margin-bottom: 0;
        color: $link-color;
    }

    .button {
        width: 100%;
    }

    button {
        padding-right: 0 !important;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    a,
    button {
        white-space: nowrap;
    }
}

.dropdown__wrapper {
    button:first-child {
        color: var(--color-white);
    }

    >.dropdown .button {
        width: 100%;
    }

    // copied styles
    @media print,
    (width <=73.8125rem) {
        margin-left: -12px;
        margin-right: -12px;
        width: auto;
        padding-left: 12px;
        padding-right: 12px
    }
}

.dropdown__menu {
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    top: 90%;
    right: 0;
    padding: $spacer;
    white-space: nowrap;
    border: 1px solid $border-color;
    background-color: var(--color-white);
    box-shadow: 0 0 5px rgba(100, 100, 100, .5);

    h2 {
        font-size: 1.1em;
        margin-bottom: 0.5em !important;
    }

    ul {
        margin-bottom: 0;
    }

    @media print {
        display: none;
    }
}

.dropdown--open .dropdown__menu {
    display: flex;
}

// a4-comments dropdown - bootstrap overwrites
.a4-comments__dropdown {
    .dropdown-toggle {
        position: absolute;
        top: -40px;
    }

    .dropdown-menu {
        --bs-dropdown-min-width: 0; // Bootstrap custom property overwrite

        padding: $spacer;
        white-space: nowrap;
        border: 1px solid $border-color;
        border-radius: 0;
        background-color: var(--color-white);
        box-shadow: 0 0 5px rgba(100, 100, 100, .5);
    }

    .dropdown-item {
        color: $link-color;
        padding: 0;
        margin-bottom: 0.3em;

        &:hover {
            background-color: transparent;
            text-decoration: underline;
        }

        button {
            padding: 0;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
