.a4-combo-box {
    position: relative;

    .form-label {
        font-weight: bold;
    }

    &__combobox {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &:not(.a4-combo-box--autocomplete) {
        .a4-combo-box__combobox {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMjMzLjQgNDA2LjZjMTIuNSAxMi41IDMyLjggMTIuNSA0NS4zIDBsMTkyLTE5MmMxMi41LTEyLjUgMTIuNS0zMi44IDAtNDUuM3MtMzIuOC0xMi41LTQ1LjMgMEwyNTYgMzM4LjcgODYuNiAxNjkuNGMtMTIuNS0xMi41LTMyLjgtMTIuNS00NS4zIDBzLTEyLjUgMzIuOCAwIDQ1LjNsMTkyIDE5MnoiLz48L3N2Zz4=") !important;
            background-repeat: no-repeat;
            background-position: right 0.5em center;
            background-size: 1em;
            padding-right: 2em;
        }
    }
}

input.a4-combo-box__combobox {
    border: 0;
    line-height: normal;
    min-height: 0;
    padding: 0;
    width: 12.5em;

    &:focus {
        outline: 0;
        border: 0;
        box-shadow: none;
    }
}

.a4-combo-box__input-wrapper {
    display: flex;
    align-items: center;
}

.a4-combo-box__input-wrapper:has(input:focus) {
    outline: 2px solid Highlight;
    outline: 5px auto -webkit-focus-ring-color;
}

.a4-combo-box__before {
    margin-right: 1em;
}

.a4-combo-box__after {
    margin-left: 1em;
}

.a4-combo-box__container,
.rbt-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    list-style: none;
    margin-top: .25em;
    padding: 0;
    overflow: auto;
    max-height: 15em;

    &--opened {
        display: block;
    }
}

.a4-combo-box__option,
.rbt-menu .dropdown-item {
    margin: 0;
    padding: .4em .5em;
    cursor: pointer;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color .2s ease-in-out;
    background-color: $white;

    &:last-child {
        border-bottom: none;
    }

    &--focus {
        outline: 3px auto Highlight;
        outline: 3px auto -webkit-focus-ring-color;
        outline-offset: -3px;
    }

    &:hover {
        background: $gray-lighter;
    }
}

.a4-combo-box__title {
    display: block;
    color: $gray;
    font-size: 0.875rem;
    padding: 0.25rem 0.5em;
}

.a4-combo-box__info {
    display: block;
    color: $black;
    padding: 0.4em 0.5em;
}

.a4-combo-box__group-item {
    margin: 0;
}

.a4-combo-box__group-dropdown {
    border-bottom: 1px solid $border-color;
    padding: 0;
    margin: 0 0 0.25rem;
}

.a4-combo-box__container li:last-child .a4-combo-box__group-dropdown {
    border-bottom: 0;
    margin-bottom: 0;
}
