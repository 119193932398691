.info-bar-block {
    @media screen and (min-width: $breakpoint-tablet-landscape) {
        display: flex;
        align-items: start;
    }

    h2, h3, h4 {
        margin-top: 0;
    }

    p {
        margin-bottom: 0;
    }
}

.info-bar-block__image {
    margin-bottom: 1em;
    max-width: 90px;
    height: auto;


    @media screen and (min-width: $breakpoint-tablet-landscape) {
        max-width: unset;
        margin-bottom: 0;
        margin-right: 1.5em;
    }
}

.info-bar-block__link {
    margin-top: 1em;

    @media screen and (min-width: $breakpoint-tablet-landscape) {
        margin-top: 2.75em;

        .info-bar-block--image & {
            margin-top: 3.75em;
        }
    }
}
