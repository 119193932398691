.details summary {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details summary > h2 {
    font-size: 1.375em;
    line-height: 1.5em;
    margin: 0;
}

.details summary > h3 {
    line-height: 1.5em;
    margin: 0;
    padding: 10px 0;
}

.details summary::marker {
    display: none;
    content: "";
}

// Hides the default arrow on safari
.details summary::-webkit-details-marker {
    display: none;
}

.details summary > span {
    transition: transform .2s;
    display: flex;
}

.details summary > span:before {
    font-size: 22px;
    align-self: center;
}

.details[open] summary > span {
    transform: rotate(-180deg);
}



