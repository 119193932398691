$font-family-base: "Arial", "Helvetica", sans-serif !default;
$font-family-sans-serif: "Arial", "Helvetica", sans-serif !default;

$font-size-xxxl: 2.25rem !default; // 36px
$font-size-xxl: 1.95rem !default; // 31.2px
$font-size-xl: 1.56rem !default; // 24.96px
$font-size-lg: 1.25rem !default; // 20px
$font-size-md: 1.125rem !default; // 18px
$font-size-base: 1rem !default;
$font-size-sm: 0.9rem !default; // 14.44px
$font-size-xs: 0.8rem !default; // 12.8px
$font-size-xxs: 0.5rem !default; // 8px

$mobile-headline-size: 0.75 !default;
