// FIXME ensure auto added buttons look inline with rest of UI eg:close on alerts
button {
  appearance: none;
  color: inherit;
  background: none;
  font-size: inherit;
  border: 0;
  cursor: pointer;
}

.widget--numberinput input {
  @extend .form-control;
}

.errorlist {
  padding-left: 0;
}

.formgroup__help {
  font-size: 14px;
  line-height: 1.3;

  label + & {
    margin-top: -0.4em;
    margin-bottom: 0.4em;
  }
}

.form-check:has(.form-check-input) {
  display: flex;
}

.form-check-input {
  flex: 0 0 auto;
  float: none !important;
  margin-left: 0 !important;
}

textarea.form-control {
  resize: vertical;
}

:not(.panel--heavy) .form-check {
  padding: 0;
}

.facet {
  // browsers set this to min-width: min-content by default for fieldsets
  // causing scrollbars in some scenarios, this fixes it
  min-width: auto;
}
