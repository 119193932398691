.icon-list {
    background: $secondary;
    color: $text-inverted;
    padding-bottom: 1.75em;
    padding-top: 1.5em;
    margin: 0 -12px;

    @media screen and (min-width: $breakpoint-tablet-landscape) {
        margin: 0;
    }

    h2, h3, h4 {
        color: $text-inverted;
        margin-top: .25em;
    }
}

.icon-list__wrapper {
    display: grid;
    gap: 2em 1em;
    list-style: none;
    align-items: stretch;

    @media screen and (min-width: $breakpoint-tablet) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

.icon-list__heading {
    margin-top: 0;
    margin-bottom: .5rem;
}

.icon-list__single {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.icon-list__single__body {
    margin-top: auto;
}

.icon-list__single__img {
    margin-top: auto;
}
