.mb-project-overview {
    grid-column: first / last !important;
}

.projects-list {
    list-style: none;
    margin: 0 0 1.5em;
    padding: 0;

    > li {
        padding: .8em 0;
        border-bottom: 1px solid $gray-lighter;

        @media screen and (min-width: $breakpoint-palm) {
            margin-bottom: 0;
            padding: 0;
            border-bottom: 0;
        }
    }

    &.container {
        margin: 0 auto 1.5em;
    }
}

.projects-list--vertical {
    @media screen and (min-width: $breakpoint-palm) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3em 1.5em;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.projects-list__list {
    @media screen and (min-width: $breakpoint-tablet) {
        padding: 1.5em 0.875em;
    }

    .container & {
        @media screen and (min-width: $breakpoint-tablet) {
            padding: 1.5em 0;
        }
    }
}

.projects-list__wrapper--combined {
    @media screen and (min-width: $breakpoint-tablet) {
        display: flex;
        position: relative;
        align-items: start;
    }

    .projects-list__list {
        display: none;

        @media screen and (min-width: $breakpoint-tablet) {
            display: block;
        }
    }

    .projects-list__map {
        @media screen and (min-width: $breakpoint-tablet) {
            position: sticky;
            top: 65px;
        }
    }

    .geomap-container {
        height: 600px;
        max-height: calc(100dvh - 65px);

        @media screen and (min-width: $breakpoint-tablet) {
            height: 1100px;
        }
    }

    .projects-list__list,
    .projects-list__map {
        flex: 1;
    }
}

.projects-list__icon-switch {
    margin-bottom: 1em;

    @media screen and (min-width: $breakpoint-tablet) {
        display: none;
    }
}

.projects-list__toggle-switch {
    margin-left: auto;
    margin-bottom: 0;
    display: none;

    @media screen and (min-width: $breakpoint-tablet) {
        display: block;
    }
}

.projects-list__list-meta {
    display: flex;

    @media screen and (min-width: $breakpoint-tablet) {
        align-items: center;
        padding: 0 0.875em 1em;
    }

    .container & {
        @media screen and (min-width: $breakpoint-tablet) {
            padding: 0 0 1em;
        }
    }
}

.projects-list__wrapper--combined .projects-list__list-meta:not(.projects-list__list-meta--no-results) {
    border-bottom: 1px solid $gray-lighter;
}

.projects-list__list-meta--no-results div:first-child {
    max-width: 50ch
}

