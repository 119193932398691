@import "variables/fonts";
@import "variables/colors";
@import "variables/breakpoints";
@import "variables/spacing";

$input-padding-y: 0.4rem !default;
$input-padding-x: 0.6rem !default;

// set bootstrap modal variables
$modal-inner-padding: 1.5 * $spacer !default;
$modal-content-border-radius: 0 !default;
$modal-header-padding-y: 0 !default;
$modal-footer-margin-between: .5rem !default;

$enable-dark-mode: false !default;
$enable-caret: false;

$infographic-footer-height: 18.75rem;
$infographic-footer-height-mobile: 43rem;