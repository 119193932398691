.phase-info {
    background-color: $panel-dark;
    color: $white;

    .fas {
        margin-right: .25em;
    }
}

.phase-info__title {
    color: inherit;
    margin: 0;
}

.phase-info__description {
    margin-bottom: 0;
}

.phase-info__cta {
    // enforce button to stick to bottom of container
    margin-top: auto;
}

.phase-info .modul-servicepanel .fa, .modul-servicepanel .far, .modul-servicepanel .fas {
    // overwrite icon color
    color: inherit;
}

@media print, (width <= 37.501rem) {
    .phase-info {
        margin: 0;
    }
}

@media print, (width >= 37.501rem) and (width <= 73.813rem) {
    .phase-info {
        margin: $spacer auto;
    }
}
