.card--like {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 9.5rem;
    padding: 1.1rem;
}

.card__like {
    margin-top: auto;
    padding-top: 1rem;
}

.card__tools {
    bottom: 0;
    position: absolute;
    right: 0;
}

.card__tools .functions {
    display: flex;
    order: 1;
    align-self: flex-end;
}

.card__stats {
    display: flex;
    margin-bottom: .75em;
    width: 100%;

    @media screen and (min-width: $breakpoint-tablet) {
        width: auto;
        margin-bottom: 0;
        margin-right: 2 * $spacer;
    }
}

.card__stat {
    display: flex;
    margin-left: 0.75 * $spacer;
    margin-bottom: 0;

    &:first-child {
        margin-left: 0;
    }

    b {
        margin-right: 0.25 * $spacer;
    }
}

.card__footer--light {
    @media screen and (min-width: $breakpoint-tablet) {
        display: inline-flex;
    }
}

.card__header .title--no-underline:hover {
    cursor: default;
    text-decoration: none;
}

.card__link {
    color: $link-color;
}

.card__link--hover {
    text-decoration: underline !important; // BO overide
}

.card__pill {
    margin-bottom: 0.3em;
}

.card__footer .functions {
    display: flex;
    order: 1;
    align-self: flex-end;
    margin-top: -9px;
    margin-right: -18px;
}

.card__button i {
    font-size: 14px;
}

.card__button {
    color: $gray;
    margin-left: 4px;
}

.card__button:hover:before {
    background-color: $primary-400;
}

.card__button--active:before {
    background-color: $primary;
}

.card__button--active i,
.card__button:hover i {
    color: $body-bg;
}

.card--archived .card__stats,
.card--archived .title,
.card--archived .pill:not(.pill--archived) { 
    filter: grayscale(100%);
    opacity: 0.7;
}

.pill--archived,
.card__link {
    filter: none;
    opacity: 1;
}

// Needed for due to Bo js not working
.card__header .title:hover {
    cursor: pointer;
    text-decoration: underline;
}