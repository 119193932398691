.swiper-container {
    background-color: inherit;
}

.swiper-navigation {
    display: flex;
    align-items: center;
    background-color: inherit;
    color: $white;
    padding-bottom: $spacer;
}

.swiper-pagination-clickable {
    display: flex;
    justify-content: center;
    align-items: center;
    background: inherit !important;
    margin-left: .5em;

    .swiper-pagination-bullet {
        display: flex;
        justify-content: center;
        background: transparent !important;
        color: $white !important;
        font-size: small;
        width: 20px !important;
        height: 20px !important;
        border: 1.5px solid $white;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background: $white !important;
        color: $panel-dark !important;
    }
}

.swiper-button-prev {
    padding-left: 0 !important;
}

.swiper-button-prev,
.swiper-button-next {
    background-color: inherit !important;
    height: 0 !important;
    display: flex;
    align-items: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: $white !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
}
