.notifications {
    margin-bottom: 5rem;
}

.feed-list {
    border-top: 1px solid $gray-lighter;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding-top: 2.5rem;
}

.feed-list:last-child {
    margin-bottom: 0;
}

.feed-list__text {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    @media screen and (min-width: $breakpoint-palm) {
        flex-direction: row;
        margin-bottom: 0;
    }
}

.feed-list__title {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 0;
}

.feed-list__count {
    background-color: $link-color;
    border-radius: 1.8125rem;
    color: $white;
    display: block;
    font-size: 1rem;
    line-height: 1.8125rem;
    height: 1.8125rem;
    margin-left: 0.625rem;
    padding: 0 0.625rem;
}

.feed-list__description {
    margin-bottom: 1.875rem;
}

.feed-list__mark-as-read {
    margin-left: auto;
}

.feed-list__mark-as-read-button {
    padding: 0;
}

.feed-list__mark-as-read-button:hover {
    text-decoration: underline;
}

.feed-list__items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.feed-list__item {
    margin-bottom: 0.625rem;

    &:last-child {
        margin-bottom: 1.875rem;
    }
}

.feed-item {
    border: 1px solid $gray-lighter;
    display: flex;
    padding: 0.75rem;

    @media screen and (min-width: $breakpoint-palm) {
        padding: 1.125rem 1.125rem 1.125rem 0.875rem;
    }
}

.feed-item--unread {
    border-left: 4px solid $link-color;
}

.feed-item__content {
    width: 100%;
}

.feed-item__info {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.625rem;
}

.feed-item__icon {
    align-items: center;
    background-color: $gray-lighter;
    border-radius: 2rem;
    display: flex;
    font-size: 0.8125rem;
    height: 2rem;
    min-height: 2rem;
    justify-content: center;
    margin-right: 0.75rem;
    position: relative;
    min-width: 2rem;
    width: 2rem;
}

.feed-item__title {
    font-size: 1rem;
    margin: 0;
}

.feed-item__title--read {
    font-weight: normal;
}

.feed-item__meta {
    color: $gray;
    display: flex;
    font-size: 0.875rem;
    gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
}

.feed-item__meta-item {
    margin-bottom: 0;
}

.feed-item__date {
    color: $gray-darkest;
    font-size: 0.875rem;
    margin-left: 0.75rem;
    margin-left: auto;
    text-align: right;
    min-width: 20%;

    @media screen and (min-width: $breakpoint-palm) {
        font-size: 1rem;
        min-width: 8rem;
        width: auto;
    }
}

.feed-item__unread {
    background-color: $link-color;
    border-radius: 0.625rem;
    bottom: 0;
    height: 0.625rem;
    position: absolute;
    right: 0;
    width: 0.625rem;
}

.feed-item__panel {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;

    @media screen and (min-width: $breakpoint-palm) {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
}

.feed-item__panel--filled {
    background-color: $gray-lightest;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.feed-item__panel-text {
    display: flex;
    align-items: center;
}

.feed-item__thumbnail {
    object-fit: cover;
    margin-right: 0.625rem;
    height: 2.5rem;
    width: 2.5rem;
}

.feed-item__body {
    font-size: 0.875rem;
    margin-bottom: 0;
}

.feed-item__body--thumbnail {
    font-size: 1rem;
}

.feed-item__link,
.feed-item__link:visited {
    color: $link-color;
    margin-top: 0.3125rem;
    margin-left: auto;
    text-align: right;

    @media screen and (min-width: $breakpoint-palm) {
        min-width: 8rem;
        margin-top: 0;
    }
}

.feed-list__pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;
}

.feed-list__pagination-item {
    border-bottom: 1px solid $black;
    border-left: 1px solid $black;
    border-top: 1px solid $black;
    height: 2.5rem;
    position: relative;
    text-align: center;
    width: 2.5rem;
}

.feed-list__pagination-item:last-child {
    border-right: 1px solid $black;  
}

.feed-list__pagination-item--active:after {
    background-color: $black;
    content: "";
    height: 0.375rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.feed-list__pagination-icon {
    font-size: 0.75rem;
}

.feed-list__pagination-button:hover {
    background-color: $gray-lightest;
}

.feed-list__pagination-button:disabled {
    cursor: default;
}

.feed-list__pagination-button:disabled .feed-list__pagination-icon {
    opacity: 0.3;
}

.feed-list__pagination-button:disabled:hover {
    background-color: transparent;
}