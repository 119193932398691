.servicepanel--centered {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;

    @media screen and (min-width: $breakpoint-palm) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: 1fr;

        .servicepanel__main {
            grid-column: 2;
        }

        .servicepanel__right {
            grid-column: 3;
            margin-left: auto;
        }
    }
}

.modul-servicepanel {
    &.panel--transparent {
        background: transparent;
        border: none;
        padding: 0;
    }

    h2:first-child {
        margin-top: 0;
    }
}

.modul-servicepanel.panel--colored {
    border: 0;
    background: $panel-colored;
}
