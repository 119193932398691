.a4-modal {
    border: none;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.15);
    padding: 2em 1.125em;

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.5);
    }

    @media (max-width: $breakpoint-palm) {
        width: 100%;
        max-width: 100%;
        max-height: 85vh;
        position: fixed;
        inset: auto 0 0;
        margin: 0;
        border-radius: 1em 1em 0 0;
        padding-top: 0.75em;
        transform: translateY(100%);
        animation: slide-out 200ms ease-out forwards;
        overflow-y: auto;

        &[open] {
            animation: slide-in 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
        }
    }

    @media (min-width: $breakpoint-palm) {
        min-width: 500px;
        max-width: 685px;
    }
}

.a4-modal__toggle {
    white-space: normal;

    @media (max-width: $breakpoint-palm) {
        text-align: center!important;
    }
}

.a4-modal__close {
    position: absolute;
    right: 1em;
    top: 1em;
    padding: 0;
}

.a4-modal__title {
    margin: unset;
    line-height: 1.5;
}

.a4-modal__description {
    margin-bottom: 1em;
}

.a4-modal__footer {
    padding-top: 1em;
    display: flex;
    justify-content: end;
}

.a4-modal__cancel {
    margin-right: 1.5em !important;
}

@keyframes slide-in {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(100%);
    }
}

// Edge cases

// hack to remove the report icon when used in a dropdown 
// (edge-case here: meinberlin/apps/contrib/templates/meinberlin_contrib/includes/item_detail_dropdown.html)
.a4-modal__toggle-wrapper--no-icon {
    i {
        display: none;
    }
}

// class used in a4 static/modals/UrlModal.jsx
.a4-url-modal__body {
    padding-top: 1em;

    @media (max-width: $breakpoint-palm) {
        .form-control {
            border-bottom: none;
        }
    }

    @media (min-width: $breakpoint-palm) {
        display: flex;

        .form-control {
            border-right: none;
        }
    }
}

.a4-url-modal__button {
    @extend .button; 
    @extend .button--light;  
    @extend .button--fulltone;
    @extend .button--fullwidth-palm;
    color: $text-inverted!important;
}