// firefox will not take the black color without the a selector
a.project-tile {
    display: flex;
    color: $text-base;
    font-family: $font-family-base;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &--vertical {
        flex-direction: column;
        height: 100%;
    }

    &--horizontal:not(.project-tile--map) {
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: 1.5em;
        padding-top: 1.5em;
    }
}

.project-tile__image-wrapper {
    flex: 1;
    min-width: 0;
    position: relative;

    .project-tile--map & {
        flex: 0 0 145px;

        @media screen and (min-width: $breakpoint-tablet) {
            flex: 0 0 120px;
        }
    }
}

.project-tile__icon {
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 2.3rem;
    width: 2.3rem;

    .fa {
        font-size: 1.3rem;
        margin-right: 0;
    }
}

.project-tile__image {
    object-fit: cover;
    max-width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;

    .project-tile--horizontal & {
        aspect-ratio: 112 / 115;
    }
}

.project-tile__body {
    flex: 3;
    position: relative;
    display: flex;
    flex-direction: column;

    .project-tile--horizontal & {
        margin-left: 1.25em;
    }
}

.project-tile__head {
    margin-bottom: .4em;
}

.project-tile__topics {
    margin-bottom: .4em;
}

.project-tile__title {
    margin-top: 0;
    overflow: hidden;
    display: -webkit-box; // stylelint-disable-line
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    .project-tile:hover &,
    .project-tile:focus & {
        text-decoration: underline;
    }
}

.project-tile__status {
    margin-top: auto;
    margin-bottom: .5em;
    font-size: $font-size-sm;
    color: $text-base;

    p:last-child {
        margin-bottom: 0;
    }
}

.project-tile__plan__count {
    margin-right: .25em;
}
