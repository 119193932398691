.upload-wrapper {
    position: relative;

    .form-control-file {
        cursor: pointer;
        left: 0;
        position: absolute;
        opacity: 0;
        top: 0;
    }
}

.upload-wrapper__fields {
    .form-hint {
        margin-top: 0.4em;
    }

    .form-control-file-dummy {
        padding-left: 60px;
    }
}

.clear-image {
    @extend .aural;
    top: 0;
    left: -1000px;

    &:focus {
        outline: 0;
        border: 0;
    }
}

.clear-image:checked + .upload-wrapper__preview img {
    opacity: 0.2;
    filter: grayscale(100%);
}

.upload-wrapper__action {
    position: absolute;
    top: 12px;
    right: 15px;
    z-index: 1;
    cursor: pointer;
}

.clear-image:focus ~ .upload-wrapper__fields .upload-wrapper__action {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px 0 rgba(102, 175, 233, 0.8);
    outline: 2px solid #0076dd;
    outline-offset: 2px;
}

.upload-wrapper .form-control-file:focus ~ .upload-wrapper__fields .form-control-file-dummy {
    border: 2px solid #0076dd;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px 0 rgba(102, 175, 233, 0.8);
    outline: 2px solid #fff;
    outline-offset: 0;
}

.upload-wrapper__preview {
    position: absolute;
    overflow: hidden;
    background-color: $gray-lightest;
    width: 50px;
    height: 33px;
    top: 5px;
    left: 5px;
    z-index: 1;
}
