.live-question {
    display: flex;
    flex-direction: column;
}

.live-question__icon-switch {
    margin-left: auto;
    margin-bottom: .625em;
}

.live-question__progress {
    border-radius: 1.2rem;
    display: flex;
    font-size: 1rem;
    line-height: 1.3;
    height: 1.2rem;
    gap: 1rem;
    margin-bottom: 0.8rem;
    position: relative;
}

.live-question__progress--active {
    background-color: $body-bg;
}

.live-question__progress-bar {
    background-color: var(--color-message-success);
    border-radius: 1.2rem;
    height: 100%;
    position: absolute;
    z-index: 0;
}

.live-question__progress-bar--highlight {
    background-color: $primary;
}

.live-question__progress-text {
    display: flex;
    gap: 0.5rem;
    padding: 0 0.5rem;
    position: relative;
    z-index: 1;
}

.live-question__progress-percentage {
    font-weight: bold;
}

.live-question__buttons {
    @media screen and (min-width: $breakpoint-tablet) {
        display: flex;
        gap: 1.25rem;
    }
}

.live-question__present {
    min-width: 14rem;
    width: 100%;
}

.live_question__count {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.live-question__wrapper {
    padding: 2rem 0;
    min-height: calc(100vh - $infographic-footer-height-mobile);

    @media screen and (min-width: $breakpoint-tablet) {
        padding: 5rem 0;
        height: calc(100vh - $infographic-footer-height - 2rem);
        overflow-y: scroll;
        scrollbar-width: none;
    }
}

.live-question__question-container {
    padding: 1rem 0;
    border-top: 1px solid $border-color;
    list-style: none;

    &:last-child {
        border-bottom: 1px solid $border-color;
    }
}

.live-question__question-container h3 {
    margin-top: 0;
    margin-bottom: 1rem;
}