// styling of a4 utility classes
.a4-sr-only {
    @extend .aural;
}

// BO use of classes media query altered to suit linting
@media print, (width >= 37.501rem) {
    .row--reverse-palm {
        flex-direction: row-reverse;
    }
}

// needed for a4-comments
.d-none {
    display: none;
}

.u-spacer-top {
    margin-top: $spacer;
}

// BO defines this as black so overwritting
.text--color-primary {
    color: $primary;
}

.text--color-dark {
    color: var(--color-black);
}

.icon--color-link {
    color: var(--color-text-link)!important;
}

// BO mimic of my-0
.mt-0 {
    margin-top: 0 !important;
}

// BO mimic of my-0.5
.mt-1 {
    margin-top: 0.5em;
}

.mt-2 {
    margin-top: 1em;
}

.mb-1 {
    margin-bottom: .5em;
}

.mb-2 {
    margin-bottom: 1em;
}

.mb-3 {
    margin-bottom: 1.5em;
}

.mr-1 {
    margin-right: 0.5em;
}

.ml-1 {
    margin-left: 0.5em;
}

.ml-2 {
    margin-left: 1em;
}

.u-flex-end {
    display: flex;
    justify-content: flex-end;
}

.u-list-reset {
    list-style: none;
    margin: 0;
    padding: 0;

    > * {
        display: block;
    }
}
