// `a` to override the bo base style `a:link` to keep a black text color
a.module-tile {
    padding: 1.5rem 1.625rem 1.25rem;
    border: 1px solid $gray-lighter;
    color: $text-base;
    min-height: 20em;
    display: flex;
    flex-direction: column;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    .status-bar {
        margin-top: .75em;
    }

    .status-bar__timespan {
        margin-bottom: 0;
    }
}

.module-tile__title {
    margin-top: 0;

    .module-tile:hover &,
    .module-tile:focus &, {
        text-decoration: underline;
    }
}

.module-tile__insight {
    margin-bottom: auto;
    color: $text-light;
    font-size: $font-size-sm;
}

.module-tile__timespan {
    vertical-align: middle;
    font-size: $font-size-sm;
}

.module-tile__timespan i,
.module-tile__timespan time {
    display: inline-block;
}
