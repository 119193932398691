// Using overflow: hidden (as used in the styleguide) causes position: sticky
// to not work as expected. See https://www.terluinwebdesign.nl/en/blog/position-sticky-not-working-try-overflow-clip-not-overflow-hidden/

// Also need to increase specificity because there is a landesfooter.css being loaded
// at the foot of the page which includes some css that would otherwise override this

// stylelint-disable-next-line
body:not(_)  {
    overflow: clip;
}
