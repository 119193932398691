.status-bar {
    width: 100%;
    height: .3em;
    appearance: none;
    background: $message-light-green;
    border: none;
    margin-bottom: .4em;

    &::-moz-progress-bar {
        background: $primary;

    }

    &::-webkit-progress-bar {
        background: $message-light-green;
    }

    &::-webkit-progress-value {
        background: $primary;
    }
}

.status-bar__timespan {
    font-weight: 400;
    font-size: $font-size-sm;
}
