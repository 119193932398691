.video-wall {
    width: 100%;
    aspect-ratio: 16/6;
    border: none;
    position: relative;
    background: rgba($white, 0.85) url("/static/images/placeholder.png");
    background-blend-mode: lighten;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.375em;

    button {
        @extend .button, .button--play
    }

    .video-link,
    strong {
        display: none;
    }
}
