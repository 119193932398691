.info-box {
    margin: $spacer 0;
    padding: $spacer;
    background-color: $message-light-blue;
}

.info-box__title {
    margin-top: 0;
}

.info-box__text {
    margin: 0;
}