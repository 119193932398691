.alert { 
    position: relative;
    margin: $padding 0;
    background-color: $message-light-blue;
}

.alert a {
    color: inherit;
    text-decoration: underline;
}

.alert__content {
    padding: 1.5rem 2rem 1.5rem 1rem;
    margin: 0 auto;
    max-width: 81rem;
}

.alert__headline {
    margin: 0;
    line-height: 1.353em;
    padding: 0;
    color: inherit;
}

.alert__text {
    margin: 0;
    padding: 0;
}

.alert__close {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    padding: 0.25rem;
    border: 0;
    color: inherit;
    cursor: pointer;
}

.alert--success {
    background-color: $message-light-green;
    color: $text-base;
}

.alert--error,
.alert--danger {
    background-color: $message-light-red;
    color: $text-error;
}

.alert--warning {
    background-color: $message-light-yellow;
    color: $text-base;
}

.alert--small {
    padding: 0.5em;
}
